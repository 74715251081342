import { Modulo, Perfil } from ".";

export class PerfilModulo implements Shared.IEntity<number>{
    
    id: number = 0;
    perfilId: number = 0;
    perfil!: Perfil;
    moduloId: number = 0;
    modulo!: Modulo;

    constructor(model?: PerfilModulo){
        
        if(!model)
            return;        

        this.id = model.id;
        this.perfilId = model.perfilId;
        this.perfil = model.perfil;
        this.moduloId = model.moduloId;
        this.modulo = model.modulo;

    }
}
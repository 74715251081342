import Vue from 'vue';

//HOME
import HomeArcaCloud from "./arcacloud/Home.vue"
import HomeLightDesk from "./lightdesk/Home.vue"
import HomeGestaoPedidoImportacao from "./gestaoPedidoImportacao/Home.vue"
import HomeGestaoBoletimDiarioApropriacao from "./gestaoBoletimDiarioApropriacao/Home.vue"

Vue.component("home-arcacloud", HomeArcaCloud);
Vue.component("home-lightdesk", HomeLightDesk);
Vue.component("home-gestaopedidoimportacao", HomeGestaoPedidoImportacao);
Vue.component("home-gestaoboletimdiarioapropriacao", HomeGestaoBoletimDiarioApropriacao);
//

//RECURSOS
import MasterPage from "./shared/MasterPage.vue"
import BreadCrumb from "./shared/BreadCrumb.vue"
import Avatar from "./shared/Avatar.vue"
import AlterarSenha from './shared/AlterarSenha.vue';
import StatusChip from "./shared/StatusChip.vue";
import TextSearch from "./shared/TextSearch.vue";
import ModuloCard from "./shared/ModuloCard.vue";

Vue.component("master-page", MasterPage);
Vue.component("alterar-senha", AlterarSenha);
Vue.component("breadCrumb-component", BreadCrumb);
Vue.component("status-chip", StatusChip);
Vue.component("avatar-component", Avatar);
Vue.component("text-search", TextSearch);
Vue.component("modulo-card", ModuloCard);
//

//CADASTROS
import CadastroEmpresa from "./geral/empresa/Cadastro.vue"
import CadastroPerfil from "./geral/perfil/Cadastro.vue"
import CadastroUsuario from "./geral/usuario/Cadastro.vue"

Vue.component("cadastro-empresa", CadastroEmpresa);
Vue.component("cadastro-perfil", CadastroPerfil);
Vue.component("cadastro-usuario", CadastroUsuario);
//
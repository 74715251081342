import { http, httpHeader } from "@/ApiConfig";

   
export class AutenticadorService {

    private _nomeControle = 'v1/autenticador';

    public async AutenticarUsuario(model: any){
        return await http.post(`${this._nomeControle}/usuario`, model);
    }

    public RetriveSessionApp() {
        return http.get(`${this._nomeControle}/RetrieveSessionApp`, httpHeader);
    }

    public async ResetarUsuario(model: any){
        return await http.post(`${this._nomeControle}/resetarSenha`, model);
    }
}
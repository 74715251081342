import { http, httpHeader } from '@/ApiConfig';
import { Service } from '../shared/';

export class PerfilService extends Service {

  constructor(){
    super('v1/perfil');
  }

  public AutoComplete(preCarregado: boolean, q?: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
      params: {
        preCarregado,
        q
      },
      headers: httpHeader.headers
    });
  }
  
  CarregarFuncionalidades(){
    return http.get(`${this.GetNomeControle()}/Funcionalidades`);
  }

}
<template>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent>
        <v-card style="border-radius: 0px !important; background: var(--v-cadastroBackground-base)">            
            <v-card-title class="pa-0 dialogHeader">
                <v-toolbar src="@/assets/images/header.jpg" flat dark height="65px">
                    <div style="display: flex; align-items: center; width: 100%; height: 100%">
                        <v-btn icon @click="Close()" class="mr-4">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{item.id ? "Editar" : "Criar"}} Empresa</v-toolbar-title>
                        <v-spacer/>
                        <v-toolbar-items>
                            <v-btn text @click="Salvar()">
                                Salvar
                            </v-btn>
                        </v-toolbar-items>
                    </div>
                </v-toolbar>
            </v-card-title>

            <v-card-text class="mt-5" :class="$vuetify.breakpoint.smAndDown ? 'px-2' : 'px-4'">
                <v-form ref="form" v-model="valid" lazy-validation>
                        
                    <!-- GERAL -->
                    <v-row dense>
                        
                        <v-col id="media" cols="12" sm="12" md="3" lg="2" :style="$vuetify.breakpoint.smAndDown ? 'display: flex; justify-content: center' : 'display: flex; flex-direction: column; justify-content: flex-start; align-items: center; margin-top: 4px'">
                            <v-card flat class="ma-1">
                                <v-card-title>
                                    <span> Foto/Logo </span>
                                </v-card-title>
                                <v-card-text style="display: flex; justify-content: center; align-items: center">
                                    <v-card width="170px" height="170px">
                                        <div v-if="!item.foto" style="width: 100%; height: 100%" >
                                            <label title="Selecionar item" for="file" style="height: 100%; width: 100%; display: flex; justify-content: center; align-items: center; cursor: pointer">
                                                <img style="width: 100px" src="@/assets/images/imageInput.png" alt="input">
                                            </label>
                                            <input @change="LoadImage()" accept="image/png, image/jpeg, image/jpg" type="file" name="file" id="file" ref="file" style="visibility: hidden"/>
                                        </div>
                                        <div v-else style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center">
                                            <v-btn icon style="position: absolute; top: 2%; right: 2%; z-index: 10; color: red" @click="RemoveImage()"><v-icon>mdi-close</v-icon></v-btn>
                                            <v-img :src="`data:image/jpeg;base64,${item.foto}`" contain width="150px" height="150px"/>
                                        </div>
                                    </v-card>
                                </v-card-text>
                                <v-card-subtitle style="text-align: center">
                                    <span>Define a foto/logo que será utilizada pelo vendedor.</span><br/>
                                    <span style="color: red">*.png, *.jpg e *.jpeg.</span>
                                </v-card-subtitle>
                            </v-card>
                        </v-col>

                        <v-spacer/>

                        <v-col id="data" cols="12" sm="12" md="9" lg="10">
                            <v-card flat>
                                <v-card-text class="mt-2">
                                    <v-row dense>
                                        <v-col cols="6" sm="3" md="2">
                                            <v-select color="primary" v-model="item.isJuridica" label="Pessoa" item-value="id" item-text="nome" :items="fisicaJuridica" dense outlined :disabled="item.id"/>
                                        </v-col>
                                        <v-col cols="12" sm="3" md="2" v-if="item.fisica">
                                            <v-text-field v-model="item.fisica.cpf" label="CPF" :rules="fieldRules" v-mask="'###.###.###-##'" dense outlined :disabled="item.id"/>
                                        </v-col>
                                        <v-col cols="12" sm="3" md="2" v-if="item.juridica">
                                            <v-text-field v-model="item.juridica.cnpj" label="CNPJ" :rules="fieldRules" v-mask="'##.###.###/####-##'" dense outlined @blur="ObterEntidadeLegal(item.juridica.cnpj)" :disabled="item.id"/>
                                        </v-col>
                                        <v-col cols="12" sm="4" md="6" lg="7">
                                            <v-text-field v-model="item.nome" label="Nome" :rules="fieldRules" :counter="120" maxlength="120" dense outlined/>
                                        </v-col>
                                        <v-col cols="6" sm="2" md="1">
                                            <v-switch color="primary" v-model="item.ativo" label="Ativo" style="margin-top: 2px"/>
                                        </v-col>                                        
                                    </v-row>
                                    <v-row dense v-if="item.juridica">                                        
                                        <v-col cols="12" sm="6" md="4">
                                            <v-text-field v-model="item.juridica.nomeFantasia" label="Nome Fantasia" :counter="60" maxlength="60" dense outlined/>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4">
                                            <v-text-field v-model="item.juridica.inscricaoEstadual" label="Inscrição Estadual" :counter="15" maxlength="15" dense outlined/>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4">
                                            <v-text-field v-model="item.juridica.inscricaoMunicipal" label="Inscrição Municipal" :counter="15" maxlength="15" dense outlined/>
                                        </v-col> 
                                    </v-row>
                                    <v-row dense v-if="item.fisica">
                                        <v-col cols="12" sm="4">
                                            <v-text-field v-model="item.fisica.identidade" label="Identidade" :counter="25" maxlength="25" dense outlined/>
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="12" sm="6" md="3">
                                            <v-text-field v-model="item.telefone1" label="Telefone 1" :counter="15" maxlength="15" v-mask="mskCelular" dense outlined/>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="3">
                                            <v-text-field v-model="item.telefone2" label="Telefone 2" :counter="15" maxlength="15" v-mask="mskCelular" dense outlined/>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-text-field v-model="item.email" label="E-mail" :counter="150" maxlength="150" dense outlined/>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-text-field v-model="item.site" label="Site" :counter="150" maxlength="150" dense outlined/>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-select v-model="item.regimeTributarioId" label="Regime Tributário"  :rules="fieldRules"  dense  item-value="id" item-text="idDescricao" :items="regimeTributarios" outlined />
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="12" sm="12">
                                            <v-textarea v-model="item.observacao" label="Observação" dense outlined/>
                                        </v-col>
                                    </v-row>
                                    
                                    <v-expansion-panels flat v-model="panel" multiple class="mb-3">
                                        
                                        <!-- ENDERECO -->
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <template v-slot:actions>
                                                    <v-icon left>mdi-chevron-down</v-icon>
                                                    <span style="display: flex; align-items: center; justify-content: center; font-size: 16px">Endereço</span>
                                                </template>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-row dense>
                                                    <v-col cols="12" sm="2">
                                                        <v-text-field v-model="item.endereco.cep" label="CEP" :rules="fieldRules" v-mask="mskCep" :counter="9" maxlength="9" outlined dense :loading="loadEndereco" @blur="ObterEndereco(item.endereco.cep)"/>
                                                    </v-col>
                                                    <v-col cols="12" sm="5">
                                                        <v-text-field v-model="item.endereco.logradouro" label="Logradouro"  :rules="fieldRules" :counter="100" maxlength="100" outlined dense/>
                                                    </v-col>
                                                    <v-col cols="12" sm="2">
                                                        <v-text-field v-model="item.endereco.numero" label="Número" :counter="15" maxlength="15" outlined dense/>
                                                    </v-col>
                                                    <v-col cols="12" sm="3">
                                                        <v-text-field v-model="item.endereco.complemento" label="Complemento" :counter="60" maxlength="60" outlined dense/>
                                                    </v-col>
                                                    <v-col cols="12" sm="2">
                                                        <v-autocomplete v-model="estadoId" label="UF" :rules="fieldRules" dense item-value="id" item-text="uf" :items="estados" outlined />
                                                    </v-col>
                                                    <v-col cols="12" sm="5">
                                                        <v-autocomplete v-model="item.endereco.municipioId" label="Municipio"  :rules="fieldRules"  dense  item-value="id" item-text="nome" :items="municipios" outlined />
                                                    </v-col>
                                                    <v-col cols="12" sm="5">
                                                        <v-text-field  v-model="item.endereco.bairro"  label="Bairro" :rules="fieldRules" :counter="60" maxlength="60" outlined dense/>
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>

                                    </v-expansion-panels>
                                </v-card-text>
                            </v-card>
                        </v-col>

                    </v-row>
                </v-form>
            </v-card-text>

            <v-overlay :value="loading">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>

        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import { Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/models/shared';
import { Empresa, Estado, Municipio } from '@/models/geral';
import { EmpresaService, RegimeTributarioService } from '@/services/geral';
import { PessoaFisica, PessoaJuridica } from '@/models/shared/PessoaBase';
import { AlertSimpleErr, AlertSimpleRes } from '@/services/shared/AlertService';
import { EnderecoService, EntidadeLegalService } from '@/services/finder'

@Component
export default class CadastroEmpresa extends CrudBase{
    @Prop() item!: Empresa;
    @Prop() value!: string;
    
    panel = [0];
    fisicaJuridica: any[] = [
        { id: true, nome: "Juridica"},
        { id: false, nome: "Física"}
    ]
    itemOriginal!: Empresa;
    service: EmpresaService = new EmpresaService();
    $refs!: {
        form: HTMLFormElement,
        file: HTMLFormElement
    }
    
    loadEndereco: boolean = false;
    enderecoService: EnderecoService = new EnderecoService();
    estadoId: number = 0;
    estados: Estado[] = [];
    municipios: Municipio[] = [];
    regimeTributarios: [] = [];
    foto: any = null;
    
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }
    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");            
        }
    }
    
    @Watch('item')
    ItemWatch(){
        if(this.item.id){
            this.itemOriginal = jiff.clone(this.item);
            this.estadoId = this.item.endereco.municipio.estadoId;
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }
    @Watch("estadoId")
    onEstadoIdChanged(value: number) {
        this.CarregarMunicipio(value);
    }
    @Watch("item.isJuridica")
    WatchJuridica(){
        if(this.item.isJuridica){
            this.item.juridica = new PessoaJuridica(this.item.juridica);
            this.item.fisica = undefined;
        }
        else{
            this.item.fisica = new PessoaFisica(this.item.fisica);
            this.item.juridica = undefined;
        }
    }
    
    async LoadImage(){
        this.foto = this.$refs.file.files[0];
        if (!this.foto) return;
        let resizeImage = require('resize-image');
        const img = document.createElement('img');

        const promise = new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(this.foto);
        reader.onload = (event) => {
            (img as any).src = (event.target as any).result;
        }
        img.onload = () => {
            let data = resizeImage.resize(img, 300, 300, resizeImage.jpeg);
            resolve(data)
            this.item.foto = data.replace(/^[^,]*,/, "")

        }
    });
    }
    RemoveImage(){
        this.item.foto = undefined;
        this.foto = null;
    }

    ObterEntidadeLegal(cnpj: string){
        this.loading = true;
        new EntidadeLegalService().ObterPorCnpj(cnpj).then(
            res => {
                const entidade = res.data;
                if (entidade) {
                    console.log(entidade);
                    this.item.nome = entidade.nome;
                    this.item.juridica!.nomeFantasia = entidade.nomeFantasia;
                    this.item.endereco.cep = entidade.endereco.cep;
                    this.item.endereco.logradouro = entidade.endereco.logradouro;
                    this.item.endereco.numero = entidade.endereco.numero;
                    this.item.endereco.complemento = entidade.endereco.complemento;
                    this.item.endereco.bairro = entidade.endereco.bairro;
                    this.estadoId = entidade.endereco.estadoId;
                    this.item.endereco.municipioId = entidade.endereco.municipioId;
                    this.item.endereco.municipioTexto = entidade.endereco.municipio;
                    this.item.endereco.estadoTexto = entidade.endereco.estado;
                    this.item.email = entidade.email;
                }
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.loading = false;
        });
    }

    Carregar(){
        this.enderecoService.ObterEstados().then(
            res => {
                this.estados = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        );

        new RegimeTributarioService().ListarTudo().then(
            res => {
                this.regimeTributarios = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        );
    }
    CarregarMunicipio(estadoId: number){
        if(estadoId > 0)
            this.enderecoService.ObterMunicipios(estadoId).then(
                res => {
                    this.municipios = res.data.items;
                },
                err => AlertSimpleErr("Aviso!", err)
            );
    }
    ObterEndereco(cep: string){
        if(cep){
            this.loadEndereco = true;
            this.enderecoService.ObterPorCep(cep).then(
                res => {
                    const endereco = res.data;
                    if (endereco) {
                        this.item.endereco.logradouro = endereco.logradouro;
                        this.item.endereco.bairro = endereco.bairro;
                        this.estadoId = endereco.estadoId;
                        this.item.endereco.municipioId = endereco.municipioId;
                    }
                },
                err => AlertSimpleErr("Aviso!", err)
            ).finally(() => {
                this.loadEndereco = false;
            });
        }
    }
    
    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }
    
    Close(){
        this.dialog = false;
    }
}
</script>

<style lang="scss">
    .v-card{
        border-radius: 5px !important;
    }
    .v-expansion-panel-header__icon{
        margin-left: 0px !important;
    }
    .v-expansion-panel-content__wrap, .v-expansion-panel-header{
        padding: 0px !important;
    }
    #media{
        order: 0 !important;
    }
    #data{
        order: 1 !important;
    }
    @media only screen and (hover: none) and (pointer: coarse){
        #media{
            order: 1 !important;
            display: flex !important;
            flex-direction: column !important;
            align-content: center !important;
            justify-content: center !important;
        }
        #data{
            order: 0 !important;
        }
    }
</style>
import {http, httpHeader} from '../../ApiFinderConfig'

export class EnderecoService {

    private _nomeControle: string = 'v1/endereco';
    
    public ObterEstados() {
        return http.get(`${this._nomeControle}/Estado?$orderby=nome`, httpHeader);
    }

    public ObterMunicipios(estadoId: number) {
        return http.get(`${this._nomeControle}/Estado/${estadoId}/Municipio?$orderby=nome`, httpHeader);
    }

    public ObterPorCep(cep: string){
        return http.get(`${this._nomeControle}/cep/${cep}`, httpHeader);
    }
}
<template>
    <v-dialog v-model="dialog" transition="dialog-bottom-transition" persistent :width="$vuetify.breakpoint.mdAndDown ? '90vw' : '50vw'">
        <v-card>

            <v-card-title class="pa-0 dialogHeader">
                <v-toolbar src="@/assets/images/header.jpg" flat dark height="55px">
                    Alterar Senha
                </v-toolbar>
            </v-card-title>

            <v-card-text class="pa-4">
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field v-model="senhaAntiga" :rules="fieldRules" :type="show1 ? 'text' : 'password'" label="Senha Atual" prepend-icon="mdi-form-textbox-password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show1 = !show1" :counter="30" maxlength="30" dense outlined/>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field v-model="senhaNova" :rules="fieldRules" :type="show2 ? 'text' : 'password'" label="Senha Nova" prepend-icon="mdi-form-textbox-password" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show2 = !show2" :counter="30" maxlength="30" dense outlined/>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field v-model="repetirSenha" :rules="[(senhaNova === repetirSenha) || 'As senhas devem ser iguais!']" :type="show3 ? 'text' : 'password'" label="Repetir Senha" prepend-icon="mdi-form-textbox-password" :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show3 = !show3" :counter="30" maxlength="30" dense outlined/>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <div style="display: flex; justify-content: flex-end; align-items: center; width: 100%">
                    <v-btn class="btn mr-4" style="color: white; font-size: 14px !important" text @click="Close()">Cancelar</v-btn>
                    <v-btn class="btnPrimary" style="color: var(--v-secondary-base); font-size: 14px !important" text @click="AlterarSenha()">Salvar</v-btn>
                </div>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import { PageBase } from '@/models/shared';
import { UsuarioService } from '@/services/geral';
import { AlertSimpleErr, AlertSimpleRes } from '@/services/shared/AlertService';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'

@Component
export default class AlterarSenha extends PageBase{
    @Prop() private value!: string;

    dialog: boolean = false;
    service: UsuarioService = new UsuarioService();
    valid: boolean = true;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    $refs!: {
        form: HTMLFormElement
    }

    senhaAntiga: string = '';
    senhaNova: string = '';
    repetirSenha: string = '';

    show1: boolean = false;
    show2: boolean = false;
    show3: boolean = false;

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    mounted() {

    }

    AlterarSenha(){

        if (this.$refs.form.validate()) {
            this.service.AlterarSenha(this.senhaAntiga, this.senhaNova).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.Close();
            })
        }
    }

    Reset(){
        this.repetirSenha = "";
        this.senhaAntiga = "";
        this.senhaNova = "";
        this.show1 = false;
        this.show2 = false;
        this.show3 = false;
        this.$refs.form.resetValidation();
    }

    Close(){
        this.Reset();
        this.dialog = false;
    }
}
</script>
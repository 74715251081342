import { UsuarioEmpresa } from "./UsuarioEmpresa";

export class Usuario implements Shared.IEntity<string>{
    
    id!: string;
    email: string = '';
    nome: string = '';
    telefone: string = '';
    ramal: string = '';
    celular: string = '';
    cargo: string = '';
    senha: string = '';
    dataHoraCriacao?: string;
    foto?: string;
    assinatura?: string;
    primeiroAcesso: boolean = true;
    token?: string;

    empresas: UsuarioEmpresa[] = [];

    constructor(model?: Usuario){

        if(!model)
            return;

        this.id = model.id;
        this.email = model.email;
        this.nome = model.nome;
        this.telefone = model.telefone;
        this.ramal = model.ramal;
        this.celular = model.celular;
        this.cargo = model.cargo;
        this.senha = model.senha;
        this.dataHoraCriacao = model.dataHoraCriacao;
        this.foto = model.foto;
        this.assinatura = model.assinatura;
        this.primeiroAcesso = model.primeiroAcesso;
        this.token = model.token;

        this.empresas = model.empresas;
    }

}
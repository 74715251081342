import { PessoaBase } from "../shared";
import { AssinanteDominio } from "./AssinanteDominio";
import { AssinanteModulo } from "./AssinanteModulo";

export class Assinante extends PessoaBase implements Shared.IEntity<string>{
    
    id: string = '';

    dominios: AssinanteDominio[] = [];
    modulos: AssinanteModulo[] = [];
 
    constructor(model?: Assinante){
        
        super(model);
        
        if(!model)
            return;        

        this.id = model.id;
        
        this.dominios = model.dominios;
        this.modulos = model.modulos;        
    }
}
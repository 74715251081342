import { Assinante, Empresa, Perfil, Usuario } from ".";

export class UsuarioEmpresa implements Shared.IEntity<number>{
    
    id: number = 0;
    usuarioId!: string;
    usuario!: Usuario;
    assinanteId!: string;
    assinante!: Assinante;
    empresaId!: string;
    empresa!: Empresa;
    perfilId?: number;
    perfil!: Perfil;
    isAssinante: boolean = false;
    clienteId?: number;
    cliente?: string;
    vendedorId?: number;
    vendedor?: string;

    constructor(model?: UsuarioEmpresa){

        if(!model)
            return;

        this.id = model.id;
        this.usuarioId = model.usuarioId;
        this.usuario = model.usuario;
        this.assinanteId = model.assinanteId;
        this.assinante = model.assinante;
        this.empresaId = model.empresaId;
        this.empresa = model.empresa;
        this.perfilId = model.perfilId;
        this.perfil = model.perfil;
        this.isAssinante = model.isAssinante;
        this.clienteId = model.clienteId;
        this.cliente = model.cliente;
        this.vendedorId = model.vendedorId;
        this.vendedor = model.vendedor;
    }

}
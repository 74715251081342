import { http } from '@/ApiConfig';
import { Service } from '../shared/';

export class SistemaService extends Service {

  constructor(){
    super('v1/sistema');
  }

  public ObterPorUrlAutenticacao(){
    return http.get(`${this.GetNomeControle()}/ObterPorUrlAutenticacao`);
  }
  
}
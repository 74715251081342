import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { colors } from 'vuetify/lib';
import pt from 'vuetify/src/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt',
  },
  theme: {
    dark: false,
    default: 'light',
    options:{
      customProperties: true
    },
    themes: { 
      light: {
        menuActive: colors.blueGrey.lighten5,
        cadastroBackground: '#eff2f5',
        chipGreen: colors.green.lighten5,
        chipFontGreen: colors.green.lighten2,
        chipRed: colors.red.lighten5,
        chipFontRed: colors.red.lighten2,
        chipBlue: colors.blue.lighten5,
        chipFontBlue: colors.blue.lighten2,
        chipYellow: colors.yellow.lighten4,
        chipFontYellow: colors.amber.accent4,
        chipLime: colors.lime.lighten3,
        chipFontLime: colors.lime.darken3,
        chipMustard: `#f5da81`,
        chipFontMustard: '#807348',
        chipPurple: `#ccccff`,
        chipOrange: colors.orange.lighten3,
        chipFontOrange: colors.orange.lighten1,
        chipDarkGrey: '#78787e',
        chipFontDarkGrey: '#303032',
        chipGrey: '#c6c5cc',
        chipFontMidGrey: '#6b6b71',
        chipFontGrey: colors.grey.lighten3,
        linkColor: colors.blue.accent4,
        canceladoRed: colors.red.lighten3,
        chipDarkBlue: '#4885e4',
        chipFontDarkBlue: '#183781'
      },
      dark: {
        menuActive: colors.blueGrey.lighten5,
        cadastroBackground: '#222222',
        chipGreen: colors.green.lighten3,
        chipFontGreen: colors.green.darken2,
        chipRed: colors.red.lighten3,
        chipFontRed: colors.red,
        chipBlue: colors.blue.lighten3,
        chipFontBlue: colors.blue.accent2,
        chipYellow: colors.amber.lighten4,
        chipFontYellow: colors.amber.accent4,
        chipLime: colors.lime.lighten3,
        chipFontLime: '#858422',
        chipMustard: `#f5da81`,
        chipFontMustard: `#807348`,
        chipPurple: `#ccccff`,
        chipFontPurple: `#a3a3cc`,
        chipOrange: colors.orange.lighten3,
        chipFontOrange: colors.orange.lighten1,
        chipDarkGrey: '#78787e',
        chipFontDarkGrey: '#303032',
        chipGrey: '#c6c5cc',
        chipFontMidGrey: '#6b6b71',
        chipFontGrey: colors.grey.darken4,
        linkColor: colors.blue.accent4,
        canceladoRed: colors.red.lighten3,
        chipDarkBlue: '#87b5e9',
        chipFontDarkBlue: '#213f61'
      }
    }
  }
});

import axios from "axios";

const httpHeader = {
    headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "include": ""
    }
};

const http = axios.create({
    baseURL: process.env.VUE_APP_ARCAAPI + "v1/",
    headers: httpHeader.headers,
});

http.interceptors.response.use(
    res => {
        return res;
    },
    err => {
        return Promise.reject(err);
    }
);

export class ArcaCloudService {    

    public PessoaAutoComplete(q: string, assinanteId: string, empresaId: string, pessoaCadastroUnico: boolean, tipo: number) {

        return http.get(`pessoa/AutoComplete/Auth`, {
            params: {
                q,
                tipo,
                assinante: assinanteId,
                empresa: empresaId,
                pessoaCadastroUnico
            },
            headers: httpHeader.headers
        });
    }

    public GeralParametros(assinanteId: string, empresaId: string){

        return http.get(`Geral/Parametro/Auth`, {
            params: {
                assinante: assinanteId,
                empresa: empresaId
            },
            headers: httpHeader.headers
        });
    }
}
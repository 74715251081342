import { http } from '@/ApiConfig';
import { Service } from '../shared/';

export class UsuarioService extends Service {

  constructor(){
    super('v1/usuario');
  }

  LiberarAcesso(id: string){
    return http.post(`${this.GetNomeControle()}/${id}/LiberarAcesso`);
  }

  async AlterarSenha(senhaAntiga: string, senhaNova: string){
    const senhas: {senhaAntiga: string, senhaNova: string} = {
      senhaAntiga: senhaAntiga,
      senhaNova: senhaNova
    };
    return await http.post(`${this.GetNomeControle()}/AlterarSenha`, senhas);
  }

  public MudarTema(){    
    return http.put(`${this.GetNomeControle()}/MudarTema`, null);
  }
  
}
export class Sistema implements Shared.IEntity<number>{
    
   id: number = 0;
   nome: string = '';
   slogan: string = '';
   url: string = '';
   urlAutenticacao: string = '';
   urlLogoPng: string = '';
   urlLogoSvg: string = '';

   constructor(model?: Sistema){

       if(!model)
           return;

       this.id = model.id;
       this.nome = model.nome;
       this.slogan = model.slogan;
       this.url = model.url;
       this.urlAutenticacao = model.urlAutenticacao;
       this.urlLogoPng = model.urlLogoPng;
       this.urlLogoSvg = model.urlLogoSvg;
   }

}
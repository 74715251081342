<template>
    <v-chip :style="`background: ${backgroundColor} !important`">
        <span :style="`color: ${fontColor}`" >{{label}}</span>
    </v-chip>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class TextSearch extends Vue {
    @Prop({required: true}) private label!: string;
    @Prop({required: true}) private fontColor!: string;
    @Prop({required: true}) private backgroundColor!: string;    
}

</script>
<template>
    <div class="moduloCard elevation-1" :style="$vuetify.theme.isDark ? 'background: rgb(33, 33, 33)' : 'background: #ffffff'" style="position: relative; border-radius: 10px 10px 0px 0px; background: #F5F5F5; cursor: pointer" @click="Clicou()">        

        <div style="position: absolute; width: 100%; height: 10%; border-radius: 10px 10px 0px 0px; opacity: 1"/>

        <div style="display: flex; flex-direction: column; align-items: center; justify-content: flex-start; width: 200px; height: 135px">
            <span class="text-center" :style="nome.length > 15 ? 'margin-top: 25px; font-size: 18px' : 'margin-top: 35px; font-size: 22px' " style="font-weight: bold">{{nome}}</span>            
            <v-icon style="position: absolute; bottom: 10px; right: 10px; font-size: 45px">{{icone}}</v-icon>
        </div>

    </div>
</template>

<script lang="ts">
import { PageBase } from '@/models/shared';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component
export default class Home extends PageBase{
    @Prop() private nome!: string;
    @Prop() private legenda!: string;
    @Prop() private icone!: string;

    Clicou(){
        this.$emit("clicou");
    }
}
</script>

<style lang="scss" scopped>

.moduloCard{
    transition: 0.5ss;
    div:first-child{
        background: gray;
        transition: 0.5s;
    }
}

.moduloCard:hover{
    color: var(--v-primary-base);
    div:first-child{
        background: var(--v-primary-base)
    }
    .v-icon{
        color: var(--v-primary-base);
    }
}

</style>
import Vue from 'vue'
import VueRouter, { NavigationGuardNext, RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: "/", redirect: "/login" },
  { path: '/login', name: 'Login', component: () => import("../views/shared/login/Login.vue") },
  { path: '/login/recuperarSenha/:token', name: 'RecuperarSenha', component: () => import("../views/shared/login/RecuperarSenha.vue") },
  
  { path: '/home', name: 'Home', component: () => import("../views/shared/Home.vue") },

  { path: '/empresa', name: 'Empresa', component: () => import("../views/geral/empresa/Lista.vue") },
  { path: '/perfil', name: 'Perfil', component: () => import("../views/geral/perfil/Lista.vue") },
  { path: '/usuario', name: 'Usuario', component: () => import("../views/geral/usuario/Lista.vue") },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

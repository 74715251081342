import { Perfil } from ".";

export class PerfilItem implements Shared.IEntity<number>{
    
    id: number = 0;
    perfilId: number = 0;
    perfil!: Perfil;
    funcionalidade: number = 0;

    constructor(model?: PerfilItem){
        
        if(!model)
            return;        

        this.id = model.id;
        this.perfilId = model.perfilId;
        this.perfil = model.perfil;
        this.funcionalidade = model.funcionalidade;
    }
}
<template>
    <v-breadcrumbs class="breadcrumb" :items="rota" :divider="divider">
        <template v-slot:item="{ item }">
            <v-breadcrumbs-item @click="item.active ? AbrirPagina(item.route) : '' ">
                <span :style="item.active ? 'text-decoration: underline; cursor: pointer' : ''" >{{item.text}}</span>
            </v-breadcrumbs-item>
        </template>
    </v-breadcrumbs>
</template>

<script lang="ts">

import { PageBase } from '@/models/shared';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component
export default class MasterPage extends PageBase{
    @Prop() private divider!: string;
    @Prop() private color!: string;

    rota: any[] = [];

    mounted() {
        document.documentElement.style.setProperty('--color', this.color);
        let count = this.$route.path.split("/").length;
        this.$route.path.split("/").forEach((x, index) => {
            let raw = "";
            let active = index != count - 1 ? true : false;
            if(x.length > 0){
                if(index == 1){
                    raw += `${x}`;
                    this.rota.push({text: "Home", route: raw, active: active})
                }
                else{
                    raw += `${x}`;
                    this.rota.push({text: x.charAt(0).toUpperCase() + x.slice(1), route: raw, active: active})
                }
            }
            
        });
    }

    AbrirPagina(name: string){
        this.$router.push({name: name})
    }
}
</script>

<style lang="scss">

    :root{
        --color: white
    }

    .breadcrumb{

        .v-breadcrumbs__divider{
            color: var(--color) !important;
        }
        .v-breadcrumbs__item{
            color: var(--color) !important;
        }

    }

</style>
<template>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent>
        <v-card style="border-radius: 0px !important; background: var(--v-cadastroBackground-base)">
            
            <v-card-title class="pa-0 dialogHeader">
                <v-toolbar src="@/assets/images/header.jpg" flat dark height="65px">
                    <div style="display: flex; align-items: center; width: 100%; height: 100%">                        
                        <v-btn icon class="mr-4" @click="Close()" :loading="loading">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>                        
                        <v-toolbar-title>{{item.id > 0 ? "Editar" : "Criar"}} Perfil</v-toolbar-title>
                        <v-spacer/>
                        <v-toolbar-items>
                            <v-btn text @click="Salvar()" :loading="loading">
                                Salvar
                            </v-btn>
                        </v-toolbar-items>
                    </div>
                </v-toolbar>
            </v-card-title>

            <v-card-text class="mt-5" :class="$vuetify.breakpoint.smAndDown ? 'px-2' : 'px-4'">
                <v-form ref="form" v-model="valid">
                    <v-card flat>
                        <v-card-text>
                            <v-row dense>
                                <v-col cols="12" sm="10">
                                    <v-text-field label="Nome" v-model="item.nome" counter="255" maxlength="255" :rules="fieldRules" dense outlined/>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-switch style="margin-top: 5px;" label="Ativo" v-model="item.ativo" dense/>
                                </v-col>
                            </v-row>
                            <v-divider style="border-top: 1px dashed gray;" class="my-4"/>
                            <v-row dense>
                                <v-col cols="12" class="pa-0">
                                    <v-card flat>
                                        <v-card-text>
                                            <v-card-title>
                                                <span>Autenticador</span>
                                            </v-card-title>
                                            <v-card-text>
                                                <v-row dense>
                                                    <v-col cols="12" sm="6" md="4" v-for="(funcao, index) in funcoes.filter(x => x.moduloId.length == 0)" :key="index">
                                                        <v-card style="background: rgb(245,245,245);" flat>
                                                            <v-card-title>
                                                                <v-checkbox class="pa-0 ma-0" :input-value="VerificarItem(funcao.id)" :label="funcao.nome" dense hide-details @change="GerenciarItem($event, funcao.id)" :disabled="funcao.desativado"/>
                                                            </v-card-title>
                                                            <v-card-text>
                                                                <v-row style="display: flex; align-items: center; justify-content: flex-start;" dense>
                                                                    <v-col cols="6" sm="6" md="4" lg="3" v-for="(item, index) in funcao.itens" :key="index">
                                                                        <v-checkbox class="pa-0 ma-0" :input-value="VerificarItem(item.id) || VerificarItem(item.parentId)" :label="item.nome" dense hide-details @change="GerenciarItem($event, item.id, item.parentId)" :disabled="item.desativado"/>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card-text>
                                        <v-divider style="border-top: 1px dashed gray;" class="my-4"/>
                                        <v-card-text>
                                            <v-card-title>
                                                <span>Módulos</span>
                                            </v-card-title>
                                            <v-card-text>
                                                <v-row dense>
                                                    <v-col cols="12" sm="3" v-for="(modulo, index) in modulos" :key="index" style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start;">
                                                        <v-checkbox :input-value="VerificarModulo(modulo.id)" @change="GerenciarModulo($event, modulo.id)"/>
                                                        <v-icon class="mr-2" :style="moduloFiltroSelecionado == modulo.id ? 'color: var(--v-primary-base)' : ''" @click="TrocarModulo(index)">mdi-{{ modulo.icone }}</v-icon>
                                                        <span style="font-size: 18px !important; cursor: pointer;" :style="moduloFiltroSelecionado == modulo.id ? 'color: var(--v-primary-base); text-decoration: underline' : ''" @click="TrocarModulo(index)">{{ modulo.nome }}</span>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card-text>
                                        <v-divider style="border-top: 1px dashed gray;" class="my-4"/>
                                        <v-card-text class="pt-0">
                                            <v-card-title>
                                                <span>Funcionalidades</span>
                                            </v-card-title>
                                            <v-card-text>
                                                <v-row dense>
                                                    <v-col cols="12" sm="4">
                                                        <v-text-field v-model="funcaoFiltro" label="Filtrar" prepend-inner-icon="mdi-filter" dense outlined/>
                                                    </v-col>
                                                    <v-spacer/>
                                                    <v-col cols="12" sm="2" style="display: flex; align-items: flex-start; justify-content: center;">
                                                        <v-btn class="mt-0 btn btn-primary" @click="RemoverTodasFuncoes()">
                                                            <span>Remover Todas</span>
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col cols="12" sm="2" style="display: flex; align-items: flex-start; justify-content: center;">
                                                        <v-btn class="mt-0 btn btn-primary" @click="AtribuirTodasFuncoes()">
                                                            <span>Adicionar Todas</span>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                                <v-row dense v-if="showFuncoes">
                                                    <v-col cols="12" sm="6" md="4" v-for="(funcao, index) in funcoesFiltradas" :key="index">
                                                        <v-card style="background: rgb(245,245,245);" flat>
                                                            <v-card-title>
                                                                <v-checkbox class="pa-0 ma-0" :input-value="VerificarItem(funcao.id)" :label="funcao.nome" dense hide-details @change="GerenciarItem($event, funcao.id)" :disabled="funcao.desativado"/>
                                                            </v-card-title>
                                                            <v-card-text>
                                                                <v-row style="display: flex; align-items: center; justify-content: flex-start;" dense>
                                                                    <v-col cols="6" sm="6" md="4" lg="3" v-for="(item, index) in funcao.itens" :key="index">
                                                                        <v-checkbox class="pa-0 ma-0" :input-value="VerificarItem(item.id) || VerificarItem(item.parentId)" :label="item.nome" dense hide-details @change="GerenciarItem($event, item.id, item.parentId)" :disabled="item.desativado"/>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-form>
            </v-card-text>            
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase } from '@/models/shared';
import { Perfil, PerfilItem, PerfilModulo } from '@/models/geral';false
import { EmpresaService, PerfilService } from '@/services/geral';
import { AlertSimpleErr, AlertSimpleRes } from '@/services/shared/AlertService';
import jiff from 'jiff'

@Component
export default class CadastroUsuario extends CrudBase{
    @Prop() private value!: string;
    @Prop() item!: Perfil;

    itemOriginal!: Perfil;
    service: PerfilService = new PerfilService();
    empresaServie: EmpresaService = new EmpresaService();
    $refs!: {
        form: HTMLFormElement
    }
    
    showModulos: boolean = false;
    modulos: any[] = [];
    modulosSelecionados: number[] = [];
    moduloFiltroSelecionado: number = 0;

    showFuncoes: boolean = false;
    funcaoFiltro: string = "";
    funcoes: any[] = [];
    funcoesFiltradas: any[] = [];
    itensSelecionados: number[] = [];

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if(this.dialog){
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id){
            this.itemOriginal = jiff.clone(this.item);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    @Watch('funcaoFiltro')
    WatchFuncaoFiltro(){
        if(!!this.funcaoFiltro){
            this.funcoesFiltradas = this.funcoes.filter(x => x.moduloId.includes(this.moduloFiltroSelecionado)).filter(x => x.nome.toLowerCase().includes(this.funcaoFiltro.toLowerCase()));
        }
        else{
            this.funcoesFiltradas = this.funcoes.filter(x => x.moduloId.includes(this.moduloFiltroSelecionado));
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Carregar(){
        this.loading = true;

        const promise1 = () => new Promise((resolve, reject) => {
            this.empresaServie.SistemaModulos().then(
                res => {
                    let modulos = res.data;
                    this.moduloFiltroSelecionado = res.data[0].id;
                    modulos.forEach(modulo => {
                        this.modulos.push({
                            id: modulo.id,
                            icone: modulo.icone,
                            nome: modulo.nome,
                            atribuido: this.item.modulos.find(x => x.moduloId == modulo.id) ? true : false
                        });
                    });
                    resolve("");
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        });
        const promise2 = () => new Promise((resolve, reject) => {
            this.service.CarregarFuncionalidades().then(
                res => {
                    let funcoes = res.data;
                    funcoes.forEach(funcao => {
                        this.funcoes.push({
                            id: funcao.id,
                            nome: funcao.nome,
                            moduloId: funcao.moduloId,
                            itens: funcao.itens
                        });
                    });
                    this.showFuncoes = true;
                    resolve("");
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        });
        
        Promise.resolve()
        .then(
            () => promise1()
        ).then(
            () => promise2()
        ).finally(() => {
            this.WatchFuncaoFiltro();
            this.CarregarSelecionados();
            this.loading = false;
        })
    }

    CarregarSelecionados(){
        let modulosSelecionados = this.item.modulos.map(x => x.moduloId);
        modulosSelecionados.forEach(modulo => {
            this.GerenciarModulo(true, modulo);
        });

        let itensSelecionados = this.item.itens.map(x => x.funcionalidade);
        itensSelecionados.forEach(funcao => {
            //SE FOR PARENT
            if(this.funcoes.find(x => x.id == funcao)){
                this.GerenciarItem(true, funcao);
            }
            else{
                let parent = this.GetFuncaoParent(funcao);
                this.GerenciarItem(true, funcao, parent);
            }
        });
    }

    TrocarModulo(index: number){
        this.moduloFiltroSelecionado = this.modulos[index].id;
        this.WatchFuncaoFiltro();
    }

    VerificarModulo(id: number) : boolean{
        this.showModulos = true;
        return this.modulosSelecionados.find(x => x == id) ? true : false;
    }

    GerenciarModulo(adicionar: any, id: number){
        this.showModulos = false;
        const promise = async () => new Promise((resolve) => {
            if(adicionar){
                if(this.modulosSelecionados.includes(id))
                    return;

                this.modulosSelecionados.push(id);
            }
            else{
                const index = this.modulosSelecionados.indexOf(id);
                if(index >= 0){
                    this.modulosSelecionados.splice(index, 1);
                }
            }
            resolve("");
        });

        Promise.resolve().then(
            () => promise()
        ).finally(() => {
            this.showFuncoes = true;
        })
    }

    VerificarItem(id: number) : boolean{
        this.showFuncoes = true;
        return this.itensSelecionados.find(x => x == id) ? true : false;
    }

    GerenciarItem(adicionar: any, id: number, parentId?: number){
        this.showFuncoes = false;
        const promise = async () => new Promise((resolve) => {
            if(adicionar){
                
                if(this.itensSelecionados.includes(id))
                    return;

                this.itensSelecionados.push(id);
                if(!(!!parentId)){
                    let children = this.funcoes.find(x => x.id == id).itens;
                    children.forEach((item) => {
                        item.desativado = true;
                        this.GerenciarItem(false, item.id, item.parentId);
                    })
                }
                else{
                    let parentItems = this.funcoes.find(x => x.id == parentId).itens.map(x => x.id);
                    if(parentItems.every(x => this.itensSelecionados.includes(x))){
                        this.GerenciarItem(true, parentId);
                    }
                }
            }
            else{
                const index = this.itensSelecionados.indexOf(id);
                if(index >= 0){
                    this.itensSelecionados.splice(index, 1);
                }
                if(!(!!parentId)){
                    let children = this.funcoes.find(x => x.id == id).itens;
                    children.forEach((item) => {
                        item.desativado = false;
                    })
                }
            }
            resolve("");
        });

        Promise.resolve().then(
            () => promise()
        ).finally(() => {
            this.showFuncoes = true;
        })
    }

    //DETERMINA QUEM DELETAR OU ADICIONAR
    Gerenciar(){
        let modulosModel = this.item.modulos.map(x => x.moduloId);
        let modulosAtuais = this.modulosSelecionados;
        let modulosAdicionar = modulosAtuais.filter(x => !modulosModel.includes(x));
        let modulosRemover = modulosModel.filter(x => !modulosAtuais.includes(x));

        modulosAdicionar.forEach(modulo => {
            let perfilModulo = new PerfilModulo();
            perfilModulo.perfilId = this.item.id;
            perfilModulo.moduloId = modulo;
            this.item.modulos.push(perfilModulo);
        });

        modulosRemover.forEach(modulo => {
            let itemModulo = this.item.modulos.find(x => x.moduloId == modulo)!;
            const index = this.item.modulos.indexOf(itemModulo);
            this.item.modulos.splice(index, 1);
        });

        let funcoesModel = this.item.itens.map(x => x.funcionalidade);
        let funcoesAtuais = this.itensSelecionados;        
        let funcoesAdicionar = funcoesAtuais.filter(x => !funcoesModel.includes(x));
        let funcoesRemover = funcoesModel.filter(x => !funcoesAtuais.includes(x));

        funcoesAdicionar.forEach(funcao => {
            let perfilItem = new PerfilItem();
            perfilItem.perfilId = this.item.id;
            perfilItem.funcionalidade = funcao;
            this.item.itens.push(perfilItem);
        });

        funcoesRemover.forEach(funcao => {
            let itemFuncao = this.item.itens.find(x => x.funcionalidade == funcao)!;
            const index = this.item.itens.indexOf(itemFuncao);
            this.item.itens.splice(index, 1);
        });
    }

    //USADO PARA PEGAR O NUMERO DA FUNCIONALIDADE E DETERMINAR SE E PARENT
    IsFuncionalidadeParent(id: number): boolean{
        let fim = id.toString().substring(6, id.toString().length)
        let numero = Number.parseInt(fim);
        return numero > 0 ? false : true;
    }

    GetFuncaoParent(id: number): number{
        return this.funcoes.find(x => x.itens.find(y => y.id == id)).id;
    }

    AtribuirTodasFuncoes(){
        this.funcoesFiltradas.forEach(funcao => {
            this.GerenciarItem(true, funcao.id);
        });
    }

    RemoverTodasFuncoes(){
        this.funcoesFiltradas.forEach(funcao => {
            this.GerenciarItem(false, funcao.id);
            funcao.itens.forEach(item => {
                this.GerenciarItem(false, item.id, funcao.id);
            });
        });
    }

    async Salvar(close: boolean = true){
        if (this.$refs.form.validate()) {
            this.Gerenciar();
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            this.loading = true;
            await (this.item.id ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    res.data.id ? this.item = res.data : -1;
                    if(close){
                        AlertSimpleRes("Aviso", res);
                        this.$emit("salvou");
                        this.Close();
                    }
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Close(){
        this.dialog = false;
        this.Clear();
    }

    Clear(){
        this.modulos = [];
        this.modulosSelecionados = [];
        this.funcoes = [];        
        this.funcoesFiltradas = [];
        this.itensSelecionados = [];
    }
}
</script>

<style lang="scss">
    .v-card{
        border-radius: 5px !important;
    }
    #userMedia{
        order: 0 !important;
    }
    #userData{
        order: 1 !important;
    }
    @media only screen and (hover: none) and (pointer: coarse){
        #userMedia{
            order: 1 !important;
            display: flex !important;
            flex-direction: column !important;
            align-content: center !important;
            justify-content: center !important;
        }
        #userData{
            order: 0 !important;
        }
    }
</style>
import { UsuarioService } from "@/services/geral";
import { AlertSimpleErr } from "@/services/shared/AlertService";
import { AppBase } from "./AppBase";

export class PageBase extends AppBase {

    public sessionApp: any;
    public app: {empresa: any, empresaFoto: string, isAssinante: boolean, perfilId: number, nome: string, usuarioFoto: string, login: string, temaEscuro: boolean, autenticacaoUrl: string, url: string, sistema: any} = {
        empresa: {},
        empresaFoto: '',
        isAssinante: false,
        perfilId: 0,
        nome: '',
        usuarioFoto: '',
        login: '',
        temaEscuro: false,
        autenticacaoUrl: '',
        url: '',
        sistema: {
            autenticacaoUrl: '',
            nome: '',
            url: '',
            modulos: []
        }
    };

    constructor(){
        super();

        if (!localStorage.sessionApp){
            this.Logout();
        }

        this.sessionApp = JSON.parse(localStorage.sessionApp);

        this.app.empresa = this.sessionApp.dados.empresa;
        this.app.empresaFoto = this.sessionApp.dados.empresaFoto;
        this.app.isAssinante = this.sessionApp.dados.isAssinante;
        this.app.perfilId = this.sessionApp.dados.perfilId;
        this.app.nome = this.sessionApp.dados.usuario;
        this.app.usuarioFoto = this.sessionApp.dados.usuarioFoto;
        this.app.login = this.sessionApp.dados.usuarioEmail;
        this.app.temaEscuro = this.sessionApp.dados.temaEscuro;
        this.app.autenticacaoUrl = this.sessionApp.dados.autenticacaoUrl;
        this.app.url = this.sessionApp.dados.url;
        this.app.sistema = this.sessionApp.dados.sistema;
    }

    MudarTema(temaEscuro: boolean){
        const usuarioService = new UsuarioService();
        usuarioService.MudarTema().then(
            res => {
                this.app.temaEscuro = res.data;
                this.sessionApp.dados.temaEscuro = res.data;
                localStorage.setItem('sessionApp', JSON.stringify(this.sessionApp));
                this.$vuetify.theme.dark = temaEscuro;
                location.reload();
            },
            err => AlertSimpleErr("Aviso!", err)
        );
    }
}
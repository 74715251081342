import { Vue } from "vue-property-decorator";
import { JSONToCSVConvertor, TableToExcel } from '@/assets/scripts/helper';

export class AppBase extends Vue {

    public emailRules = [v => !!v && /^[a-z0-9.-]+@[a-z0-9.-]+\.[a-z]+$/i.test(v) || 'E-mail precisa ser válido']
    public fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    public numberFieldRules: any[] = [(v: any) => (!isNaN(parseFloat(v)) && v >= 0) || "Campo obrigatório"];
    public numberGtZeroFieldRules: any[] = [(v: any) => (!isNaN(parseFloat(v)) && v > 0) || "Campo obrigatório"];

    listaSimNao: any = [ 
        {id: true, nome: "Sim"}, 
        {id: false, nome: "Não"}
    ];

    public mskCnpj = process.env.VUE_APP_MSK_CNPJ;
    public mskCpf = process.env.VUE_APP_MSK_CPF;
    public mskTelefone = process.env.VUE_APP_MSK_TELEFONE;
    public mskCelular = process.env.VUE_APP_MSK_CELULAR;
    public mskCep = process.env.VUE_APP_MSK_CEP;
    public mskPlaca = process.env.VUE_APP_MSK_PLACA;

    public loading: boolean = false;

    public meses: any[] = [
        { id: 1, nome: "Janeiro" },
        { id: 2, nome: "Fevereiro" },
        { id: 3, nome: "Março" },
        { id: 4, nome: "Abril" },
        { id: 5, nome: "Maio" },
        { id: 6, nome: "Junho" },
        { id: 7, nome: "Julho" },
        { id: 8, nome: "Agosto" },
        { id: 9, nome: "Setembro" },
        { id: 10, nome: "Outubro" },
        { id: 11, nome: "Novembro" },
        { id: 12, nome: "Dezembro" }
    ];

    Logout(){
        localStorage.clear();
        this.$router.push({name:"Login"});
    }

    ExportarExcel(tabela: string, worksheet?: string){
        TableToExcel(tabela, worksheet);
    }

    JsonToCSV(JSONData: JSON, ReportTitle: string, ShowLabel: boolean){
        JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel);
    }

    GetAppVersion(){
        const pack = require('@/../package.json');
        return pack.version;
    }
}
import { Endereco } from ".";

export class PessoaBase{

    nome: string = '';
    isJuridica: boolean = true;
    juridica?: PessoaJuridica = new PessoaJuridica();
    fisica?: PessoaFisica;
    dataCriacao: string = '';
    endereco: Endereco = new Endereco();
    telefone1: string = '';
    telefone2: string = '';
    email: string = '';
    site: string = '';
    foto?: string;
    observacao: string = '';
    ativo: boolean = true;

    constructor(model?: PessoaBase){

        if(!model)
            return;

        this.nome = model.nome;
        this.isJuridica = model.isJuridica;
        this.juridica = model.juridica;
        this.fisica = model.fisica;
        this.endereco = model.endereco;
        this.telefone1 = model.telefone1;
        this.telefone2 = model.telefone2;
        this.email = model.email;
        this.site = model.site;
        this.foto = model.foto;
        this.observacao = model.observacao;
        this.ativo = model.ativo;
    }
}

export class PessoaJuridica{

    cnpj: string = '';
    nomeFantasia: string = '';
    inscricaoEstadual: string = '';
    inscricaoMunicipal: string = '';

    constructor(model?: PessoaJuridica){

        if(!model)
            return;

        this.cnpj = model.cnpj;
        this.nomeFantasia = model.nomeFantasia;
        this.inscricaoEstadual = model.inscricaoEstadual;
        this.inscricaoMunicipal = model.inscricaoMunicipal;
    }
}

export class PessoaFisica{

    cpf: string = '';
    identidade: string = '';

    constructor(model?: PessoaFisica){

        if(!model)
            return;

            this.cpf = model.cpf;
            this.identidade = model.identidade;
    }
}
import axios from "axios";

export const httpHeader = {
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "include": ""
  }
};
export const http = axios.create({
  baseURL: process.env.VUE_APP_FINDERAPI,
  headers: httpHeader.headers
});
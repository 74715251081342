<template>
  <v-app>

    <!-- IMAGEM CABEÇALHO -->
    <v-img src="@/assets/images/header.jpg" class="headerImg"/>

    <!-- CABEÇALHO -->
    <div class="pageHeader">

      <v-btn class="menuButton" icon small color="white" depressed @click="drawer = !drawer" style="margin-right: 10px">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      
      <!-- <a href="/home" style="display: flex; align-items: center; justify-content: center">
        <img class="empresaLogo" src="@/assets/images/GiomLogo.png" href="/home">
      </a> -->
      
      <v-spacer/>

      <!-- MENU USUARIO -->      
      <v-menu offset-y left transition="slide-y-transition">
        
        <template v-slot:activator="{ on }">
          <v-btn color="secondary" v-on="on" fab small class="mr-2 mt-2" depressed style="border-radius: 10px">
            <avatar-component :src="app.usuarioFoto" :nome="app.nome"/>
          </v-btn>
        </template>

        <v-list class="menuList">
          
          <div class="user">
            <avatar-component :src="app.usuarioFoto" :nome="app.nome"/>
            <div style="width: 100%">
              <span style="font-size: 15px">{{app.nome}}</span> <br/>
              <span style="font-size: 12px">{{app.login}}</span>
            </div>
          </div>

          <v-divider/>

          <v-list-item v-if="app.temaEscuro" @click="MudarTema()">
            <v-list-item-icon><v-icon>mdi-invert-colors-off</v-icon></v-list-item-icon>
            <v-list-item-title>Tema Claro</v-list-item-title>
          </v-list-item>
          <v-list-item v-else @click="MudarTema()">
            <v-list-item-icon><v-icon>mdi-invert-colors</v-icon></v-list-item-icon>
            <v-list-item-title>Tema Escuro</v-list-item-title>
          </v-list-item>

          <v-list-item @click="dialogAlterarSenha = true">
            <v-list-item-icon><v-icon>mdi-lock-reset</v-icon></v-list-item-icon>
            <v-list-item-title>Alterar Senha</v-list-item-title>
          </v-list-item>

          <v-list-item @click="Logout()">
            <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item>

        </v-list>

      </v-menu>

      <!-- ACOES LISTA -->
      <div class="actions">
        
        <div style="color: white; font-size: 20px">
          
          <v-icon color="white" style="margin-right: 10px; font-size: 28px !important">{{icone}}</v-icon>
          <span>{{titulo}}</span> <br/>
          
          <breadCrumb-component divider="-"/>

        </div>

        <v-spacer/>

        <slot name="acoes"/>

      </div>
    
    </div>

    <!-- CONTEUDO -->
    <div class="pageContent">
      <v-card>
        <slot/>
      </v-card>
    </div>

    <!-- MENU LATERAL -->
    <v-navigation-drawer class="drawerMenu" fixed v-model="drawer" temporary :width=" $vuetify.breakpoint.smAndDown ? '70vw' : '300px'" dark>
      
      <div class="menuEmpresaLogo">
        <a href="/home">
          <img v-if="app.empresaFoto" :src="`data:image/jpeg;base64,${app.empresaFoto}`">
          <img v-else src="@/assets/images/GiomLogo.png">
          <!-- <img src="@/assets/images/GiomLogo.png" href="/home"> -->
        </a>
      </div>

      <v-subheader class="ml-2 mt-1">MENU</v-subheader>

      <v-divider/>
        
      <!-- ITENS DO MENU -->
      <div v-for="(opt, index) in menuOpts" :key="index">
          <v-list v-if="opt.items" nav dense class="pa-0 navDrawerMenu">
              <v-list-group :prepend-icon="opt.icon">
                  <template v-slot:activator>
                      <v-list-item-title>{{opt.name}}</v-list-item-title>
                  </template>
                  <div v-for="(item, index) in opt.items" :key="index" style="padding: 2px 10px 2px 10px">
                      <v-list-item :to="item.path" :disabled="item.restrito" style="padding-left: 10px; border-radius: 10px">
                          <v-list-item-title>{{item.name}}</v-list-item-title>
                          <v-list-item-icon><v-icon>{{item.icon}}</v-icon></v-list-item-icon>
                      </v-list-item>
                  </div>
              </v-list-group>
          </v-list>
          <v-list v-else nav dense class="pa-0 navDrawerMenu">
              <v-list-item :to="opt.path" :disabled="opt.restrito" style="padding-left: 10px; border-radius: 10px">
                  <v-list-item-title>{{opt.name}}</v-list-item-title>
                  <v-list-item-icon><v-icon>{{opt.icon}}</v-icon></v-list-item-icon>
              </v-list-item>
          </v-list>
      </div>
        
      <template v-slot:append>
      
        <v-list-item @click="Logout()" style="padding-left: 30px">
          <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Sair</v-list-item-title>
        </v-list-item>

        <div style="display: flex; align-items: center; justify-content: center; margin-bottom: 5px">
          <span style="color: white; font-size: 12px"> Versão - {{GetAppVersion()}} </span>
        </div>

      </template>

    </v-navigation-drawer>

    <alterar-senha v-model="dialogAlterarSenha" @fechou="dialogAlterarSenha = false"/>

  </v-app>
</template>

<script lang="ts">
import { PageBase } from '@/models/shared';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';


@Component
export default class MasterPage extends PageBase{
  @Prop() public icone!: string;
  @Prop() public titulo!: string;

  drawer: boolean = false;
  dialogAlterarSenha: boolean = false;

  menuOpts: any[] = [];

  created() {
    this.$vuetify.theme.dark = this.app.temaEscuro;
    this.CarregarMenu();
  }

  CarregarMenu(){
    this.menuOpts = [
        // { name: 'Despesa', icon:'mdi-account-cash', 
        //     items: [
        //         { name:'Despesa',               icon:'mdi-cash-minus',              path:'/financeiro/despesa',                     restrito:false },
        //     ]
        // },
        { name:'Home',                icon:'mdi-home',                  path:'/home',                  restrito:false },
        { name:'Empresas',            icon:'mdi-factory',               path:'/empresa',               restrito:false },
        { name:'Perfil de Acesso',    icon:'mdi-account-tag',           path:'/perfil',                restrito:false },
        { name:'Usuários',            icon:'mdi-account',               path:'/usuario',               restrito:false },
    ];
  }  

}
</script>

<style lang="scss">

  .drawerMenu{
    background: linear-gradient(160deg, 
      rgb(26, 128, 218, 0.95), 
      rgba(2, 42, 77, 0.85)) !important;
  }

  .navDrawerMenu{
    .v-list-item--active {
      color: var(--v-menuActive-base);
    }
  }

  .menuEmpresaLogo{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0px 30px 0px;
    background-color: white;
    border-radius: 0px 0px 25px 25px;
    opacity: 1 !important;
    img{
      max-width: 160px;
      max-height: 165px;
    }
  }

  .menuList{
    width: 300px;
    .user{
      margin: 0px 0px 10px 10px;
      display: inline-flex;
      padding: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .avatar{
        margin-right: 10px !important;
      }
    }
  }

</style>

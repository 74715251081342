<template>
    <master-page icone="mdi-view-module" titulo="Módulos">
        <v-card-text>
            <v-row v-if="modulos.length > 0" style="display: flex; align-content: center; justify-content: center" class="pa-6">
                <v-col cols="12" sm="4" md="3" lg="2" v-for="(item, index) in modulos" :key="index" style="display: flex; align-items: center; justify-content: center">
                    <modulo-card :nome="item.nome" :icone="item.icone" @clicou="Redirecionar(item.termo)"/>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col>
                    Nenhum módulo encontrado.
                </v-col>
            </v-row>
        </v-card-text>
    </master-page>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator';
import { PageBase } from '@/models/shared';
import { AlertSimpleErr } from '@/services/shared/AlertService';

@Component
export default class Home extends PageBase {
    
    modulos: any[] = [];    

    mounted() {
        this.Carregar();
    }    

    Carregar(){
        this.CarregarModulos();
    }

    CarregarModulos(){
        this.modulos = [];
        this.app.sistema.modulos.forEach(modulo => {
            this.modulos.push({
                nome: modulo.nome,
                icone: `mdi-${modulo.icone}`,
                termo: modulo.termo
            })
        });
    }

    Redirecionar(modulo: string){
        var token = JSON.parse(localStorage.sessionApp).token;
        var link = `${this.app.sistema.url}/auth/${modulo}?token=${token}`
        location.href = link;
    }
}
</script>


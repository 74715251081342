import { Assinante, UsuarioEmpresa } from ".";
import { PessoaBase } from "../shared";

export class Empresa extends PessoaBase implements Shared.IEntity<string>{
    
    id!: string;
    assinanteId?: string;
    assinante!: Assinante;
    regimeTributarioId!: number;

    usuarios: UsuarioEmpresa[] = [];
 
    constructor(model?: Empresa){
        
        super(model);

        if(!model)
            return;
 
        this.id = model.id;
        this.assinanteId = model.assinanteId;
        this.assinante = model.assinante;
        this.regimeTributarioId = model.regimeTributarioId;

        this.usuarios = model.usuarios;        
    } 
 }
import { Municipio } from "../geral";

export class Endereco{
    
    logradouro: string = '';
    numero: string = '';
    complemento: string = '';
    cep: string = '';
    bairro: string = '';
    municipioId: number = 0;
    municipio!: Municipio;
    municipioTexto: string = '';
    estadoTexto: string = '';

    constructor(model?: Endereco){
 
        if(!model)
            return;
 
        this.logradouro = model.logradouro;
        this.numero = model.numero;
        this.complemento = model.complemento;
        this.cep = model.cep;
        this.bairro = model.bairro;
        this.municipioId = model.municipioId;
        this.municipio = model.municipio;
        this.municipioTexto = model.municipioTexto;
        this.estadoTexto = model.estadoTexto;
    } 
 }
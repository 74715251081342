import { http } from '@/ApiConfig';
import { Service } from '../shared/';

export class EmpresaService extends Service {

  constructor(){
    super('v1/empresa');
  }
  
  SistemaModulos(){
    return http.get(`${this.GetNomeControle()}/SistemaModulos`);
  }

  Acesso(){
    return http.get(`${this.GetNomeControle()}/Acesso`);
  }
}
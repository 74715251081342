<template>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent>
        <v-card style="border-radius: 0px !important; background: var(--v-cadastroBackground-base)">
            
            <v-card-title class="pa-0 dialogHeader">
                <v-toolbar src="@/assets/images/header.jpg" flat dark height="65px">
                    <div style="display: flex; align-items: center; width: 100%; height: 100%">                        
                        <v-btn icon @click="Close()" class="mr-4">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>                        
                        <v-toolbar-title>{{item.id ? "Editar" : "Criar"}} Usuário</v-toolbar-title>
                        <v-spacer/>
                        <v-toolbar-items>
                            <v-btn text @click="Salvar()" :loading="loading">
                                Salvar
                            </v-btn>
                        </v-toolbar-items>
                    </div>
                    <template v-slot:extension>
                        <v-tabs v-model="tabActive" color="white">
                            <v-tab>Geral</v-tab>
                            <v-tab v-if="app.isAssinante">Acesso</v-tab>
                        </v-tabs>
                    </template>
                </v-toolbar>
            </v-card-title>

            <v-card-text class="mt-5" :class="$vuetify.breakpoint.smAndDown ? 'px-2' : 'px-4'">
                <v-tabs-items v-model="tabActive">
                    <v-tab-item>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-row dense>
                                <v-col id="userMedia" cols="12" sm="12" md="3" lg="2" :style="$vuetify.breakpoint.smAndDown ? 'display: flex; justify-content: center' : 'display: flex; flex-direction: column; justify-content: center; align-items: center'">
                                    <v-card flat class="ma-1">
                                        <v-card-title>
                                            <span> Foto </span>
                                        </v-card-title>
                                        <v-card-text style="display: flex; justify-content: center; align-items: center">
                                            <v-card width="170px" height="170px">
                                                <div v-if="!item.foto" style="width: 100%; height: 100%" >
                                                    <label title="Selecionar item" for="file1" style="height: 100%; width: 100%; display: flex; justify-content: center; align-items: center; cursor: pointer">
                                                        <img style="width: 100px" src="@/assets/images/imageInput.png" alt="input">
                                                    </label>
                                                    <input @change="LoadImage()" accept="image/png, image/jpeg, image/jpg" type="file" name="file1" id="file1" ref="file1" style="visibility: hidden"/>
                                                </div>
                                                <div v-else style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center">
                                                    <v-btn icon style="position: absolute; top: 2%; right: 2%; z-index: 10; color: red" @click="RemoveImage()"><v-icon>mdi-close</v-icon></v-btn>
                                                    <v-img :src="`data:image/jpeg;base64,${item.foto}`" contain width="150px" height="150px"/>
                                                </div>
                                            </v-card>
                                        </v-card-text>
                                        <v-card-subtitle style="text-align: center">
                                            <span>Define a foto que será utilizada pelo usuário.</span> <br/>
                                            <span style="color: red">*.png, *.jpg e *.jpeg.</span>
                                        </v-card-subtitle>
                                    </v-card>
                                    <v-card flat class="ma-1">
                                        <v-card-title>
                                            <span> Assinatura </span>
                                        </v-card-title>
                                        <v-card-text style="display: flex; justify-content: center; align-items: center">
                                            <v-card width="170px" height="170px">
                                                <div v-if="!item.assinatura" style="width: 100%; height: 100%" >
                                                    <label title="Selecionar item" for="file2" style="height: 100%; width: 100%; display: flex; justify-content: center; align-items: center; cursor: pointer">
                                                        <img style="width: 100px" src="@/assets/images/imageInput.png" alt="input">
                                                    </label>
                                                    <input @change="LoadAssinatura()" accept="image/png, image/jpeg, image/jpg" type="file" name="file2" id="file2" ref="file2" style="visibility: hidden">
                                                </div>
                                                <div v-else style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center">
                                                    <v-btn icon style="position: absolute; top: 2%; right: 2%; z-index: 10; color: red" @click="RemoveAssinatura()"><v-icon>mdi-close</v-icon></v-btn>
                                                    <v-img :src="`data:image/jpeg;base64,${item.assinatura}`" contain width="150px" height="150px"/>
                                                </div>
                                            </v-card>
                                        </v-card-text>
                                        <v-card-subtitle style="text-align: center">
                                            <span>Define a foto que será utilizada pelo usuário.</span> <br/>
                                            <span style="color: red">*.png, *.jpg e *.jpeg.</span>
                                        </v-card-subtitle>
                                    </v-card>
                                </v-col>
                                <v-spacer/>
                                <v-col id="userData" cols="12" sm="12" md="9" lg="10">
                                    <v-card flat>
                                        <v-card-text class="mt-2">
                                            <v-row dense>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field v-model="item.email" label="Login (E-mail)" :rules="emailRules" :counter="255" maxlength="255" dense outlined/>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="8">
                                                    <v-text-field v-model="item.nome" label="Nome" :rules="fieldRules" :counter="120" maxlength="120" dense outlined/>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-text-field v-model="item.cargo" label="Cargo" :counter="100" maxlength="100" dense outlined/>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="6">
                                                    <v-text-field v-model="item.telefone" label="Telefone" v-mask="mskTelefone" dense outlined/>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="2">
                                                    <v-text-field v-model="item.ramal" label="Ramal" :counter="15" maxlength="15" dense outlined/>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-text-field v-model="item.celular" label="Celular" v-mask="mskCelular" dense outlined/>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-tab-item>
                    <v-tab-item>
                        <v-form ref="formUsuarioEmpresa" v-model="validUsuarioEmpresa" lazy-validation>
                            <v-card flat>
                                <v-card-text>
                                    <v-row class="mb-2" dense>
                                        <v-col cols="12" sm="10">
                                            <v-row dense>
                                                <v-col cols="12" sm="6">
                                                    <v-autocomplete v-model="usuarioEmpresa.empresaId" label="Empresa" :items="empresas" @change="SelecionarEmpresa()" item-text="cpfCnpjNome" item-value="id" :rules="fieldRules" dense outlined/>
                                                    <!-- <v-autocomplete v-model="usuarioEmpresa.empresaId" label="Empresa" :items="empresas" item-text="cpfCnpjNome" item-value="id" :rules="fieldRules" dense outlined/> -->
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                    <v-autocomplete v-model="usuarioEmpresa.perfilId" label="Perfil" :items="perfis" item-text="nome" item-value="id" :rules="fieldRules" dense outlined/>
                                                </v-col>
                                                <v-col cols="12" sm="6" v-if="app.sistema.nome == 'arcacloud' && false">
                                                    <v-autocomplete :disabled="!(!!usuarioEmpresa.empresaId)" v-model="usuarioEmpresa.clienteId" :items="clientes" label="Cliente" item-value="id" item-text="cpfCnpjNome" :search-input.sync="onSearchCliente" dense outlined />
                                                </v-col>
                                                <v-col cols="12" sm="6" v-if="app.sistema.nome == 'arcacloud'">
                                                    <v-autocomplete :disabled="!(!!usuarioEmpresa.empresaId)" v-model="usuarioEmpresa.vendedorId" :items="vendedores" label="Vendedor" item-value="id" item-text="cpfCnpjNome" :search-input.sync="onSearchVendedor" dense outlined />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" sm="2" style="display:  flex; align-items: flex-start; justify-content: center;">
                                            <v-btn class="mt-0 btn btn-primary" @click="AdicionarUsuarioEmpresaAcesso()" :disabled="!validUsuarioEmpresa">
                                                <v-icon>mdi-plus</v-icon>
                                                <span>Adicionar</span>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-data-table :items="item.empresas" :headers="usuarioEmpresaHeaders" :items-per-page="-1" hide-default-footer dense>
                                        <template v-slot:[`item.actions`]="{ item }">
                                            <div style="display: flex; justify-content: center; align-items: center">
                                                <v-icon style="font-size: 18px !important" @click="ExcluirUsuarioEmpresaAcesso(item)">mdi-delete</v-icon>
                                            </div>
                                        </template>
                                        <template v-slot:[`item.perfilId`]="{ item }">
                                            <span v-if="item.isAssinante">MASTER</span>
                                            <v-autocomplete v-else class="pt-4" v-model="item.perfilId" label="Perfil" :items="perfis.filter(x => x.id != -1)" item-text="nome" item-value="id" dense/>
                                        </template>
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-form>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>            
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase } from '@/models/shared';
import jiff from 'jiff'
import { Usuario, UsuarioEmpresa } from '@/models/geral';
import { EmpresaService, PerfilService, UsuarioService } from '@/services/geral';
import { ArquivoService } from '@/services/shared';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/services/shared/AlertService';
import { ArcaCloudService } from '@/services/arcaCloud';

@Component
export default class CadastroUsuario extends CrudBase{
    @Prop() private value!: string;
    @Prop() item!: Usuario;

    itemOriginal!: Usuario;

    service: UsuarioService = new UsuarioService();
    $refs!: {
        form: HTMLFormElement,
        formUsuarioEmpresa: HTMLFormElement,
        file1: HTMLFormElement,
        file2: HTMLFormElement
    }

    foto: any = null;
    assinatura: any = null;

    validUsuarioEmpresa: boolean = true;
    usuarioEmpresa: UsuarioEmpresa = new UsuarioEmpresa();
    usuarioEmpresaHeaders: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action', use: true },
        { text: 'Empresa', value: 'empresa.cpfCnpjNome', use: true },
        { text: 'Perfil', value: 'perfilId', use: true },
        // { text: 'Cliente', value: 'cliente', use: true },
        { text: 'Vendedor', value: 'vendedor', use: true },
    ];

    empresas: any[] = [];
    empresaService: EmpresaService = new EmpresaService();

    perfis: any[] = [];
    perfilService: PerfilService = new PerfilService();

    // ARCACLOUD
    arcaService: ArcaCloudService = new ArcaCloudService();
    arcaGeralParams: any = {
        id: 0,
        empresaId: '',
        cadastroCompartilhado: {
            fabricante: false,
            pessoa: false,
            segmento: false,
            regraFiscal: false,
            centroCusto: false,
            contaGerencial: false,
            produto: false,
            categoriaProduto: false,
            unidadeMedida: false,
            formaPagamento: false,
            condicaoPagamento: false
        }
    }

    clientes: any[] = [];
    onSearchCliente: any = null;
    isClienteLoading: boolean = false;
    
    vendedores: any[] = [];
    onSearchVendedor: any = null;
    isVendedorLoading: boolean = false;
    // END ARCACLOUD

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if(this.dialog){
            this.Carregar();
            this.CarregarUsuarioEmpresasAcesso();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id){
            this.itemOriginal = jiff.clone(this.item);
        }
        if (this.$refs.form)
            this.$refs.form.resetValidation();

        if(this.$refs.formUsuarioEmpresa)
            this.$refs.formUsuarioEmpresa.resetValidation();
    }

    @Watch('onSearchCliente')
    SearchCliente(val: any){
        if(!(!!val)) return;
        if(!!this.usuarioEmpresa.clienteId) return;
        if(this.isClienteLoading) return;
        this.arcaService.PessoaAutoComplete(val, this.app.empresa.assinanteId, this.usuarioEmpresa.empresaId, this.arcaGeralParams.cadastroCompartilhado.pessoa, 1).then(
            res => {
                this.clientes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    @Watch('onSearchVendedor')
    SearchVendedor(val: any){
        if(!(!!val)) return;
        if(!!this.usuarioEmpresa.vendedorId) return;
        if(this.isVendedorLoading) return;
        this.arcaService.PessoaAutoComplete(val, this.app.empresa.assinanteId, this.usuarioEmpresa.empresaId, this.arcaGeralParams.cadastroCompartilhado.pessoa, 2).then(
            res => {
                this.vendedores = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Carregar(){
        
    }

    SelecionarEmpresa(){
        if(this.app.sistema.nome == "arcacloud"){
            this.arcaService.GeralParametros(this.app.empresa.assinanteId, this.usuarioEmpresa.empresaId).then(
                res => {
                    this.arcaGeralParams = res.data;
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

    CarregarUsuarioEmpresasAcesso(){
        this.perfilService.AutoComplete(true).then(
            res => {
                this.perfis = res.data;
                this.perfis.unshift({
                    id: -1,
                    nome: "MASTER"
                });
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.empresaService.Acesso().then(
            res => {
                this.empresas = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    async LoadImage() {
        this.foto = this.$refs.file1.files[0];
        
        if (!this.foto) 
            return;
        
        let resizeImage = require('resize-image');
        const img = document.createElement('img');

        const promise = new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(this.foto);
            reader.onload = (event) => {
                (img as any).src = (event.target as any).result;
            }
            img.onload = () => {
                let data = resizeImage.resize(img, 140, 140, resizeImage.jpeg);
                resolve(data)
                this.item.foto = data.replace(/^[^,]*,/, "");
            }
        });
    }

    RemoveImage(){
        this.item.foto = undefined;
        this.foto = null;
    }

    async LoadAssinatura(){
        this.assinatura = this.$refs.file2.files[0];
        if (!this.assinatura)
            return;
        const arquivoService = new ArquivoService();
        const dados = await arquivoService.Ler(this.assinatura);
        this.item.assinatura = dados.replace(/^[^,]*,/, "");
    }
    RemoveAssinatura(){
        this.item.assinatura = undefined;
        this.assinatura = null;
    }

    AdicionarUsuarioEmpresaAcesso(){
        if(this.$refs.formUsuarioEmpresa.validate()){
            if(this.item.empresas.find(x => x.empresaId == this.usuarioEmpresa.empresaId)){
                AlertSimple("Aviso!", "Esse item já se encontra inserido.", "warning");
                this.usuarioEmpresa = new UsuarioEmpresa();
                if(this.$refs.formUsuarioEmpresa)
                    this.$refs.formUsuarioEmpresa.resetValidation();    
                return;
            }
            this.usuarioEmpresa.empresa = this.empresas.find(x => x.id == this.usuarioEmpresa.empresaId);

            if(this.usuarioEmpresa.perfilId == -1){
                this.usuarioEmpresa.isAssinante = true;
                this.usuarioEmpresa.perfilId = undefined;
            }

            if(!!this.usuarioEmpresa.clienteId)
                this.usuarioEmpresa.cliente = this.clientes.find(x => x.id == this.usuarioEmpresa.clienteId).nome;

            if(!!this.usuarioEmpresa.vendedorId)
                this.usuarioEmpresa.vendedor = this.vendedores.find(x => x.id == this.usuarioEmpresa.vendedorId).nome;

            this.item.empresas.push(this.usuarioEmpresa);
            this.usuarioEmpresa = new UsuarioEmpresa();
            if(this.$refs.formUsuarioEmpresa)
                this.$refs.formUsuarioEmpresa.resetValidation();
        }
    }

    ExcluirUsuarioEmpresaAcesso(item: UsuarioEmpresa){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.empresas.indexOf(item);
                context.item.empresas.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            this.loading = true;
            (this.item.id ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Close(){
        this.dialog = false;
    }
}
</script>

<style lang="scss">
    .v-card{
        border-radius: 5px !important;
    }
    #userMedia{
        order: 0 !important;
    }
    #userData{
        order: 1 !important;
    }
    @media only screen and (hover: none) and (pointer: coarse){
        #userMedia{
            order: 1 !important;
            display: flex !important;
            flex-direction: column !important;
            align-content: center !important;
            justify-content: center !important;
        }
        #userData{
            order: 0 !important;
        }
    }
</style>
import { Sistema, PerfilItem, PerfilModulo, Assinante } from ".";

export class Perfil implements Shared.IEntity<number>{
    
    id: number = 0;
    assinanteId!: string;
    assinante!: Assinante;
    sistemaId!: number;
    sistema!: Sistema;
    nome: string = "";
    ativo: boolean = true;

    itens: PerfilItem[] = [];
    modulos: PerfilModulo[] = [];

    constructor(model?: Perfil){
        
        if(!model)
            return;

        this.id = model.id;
        this.assinanteId = model.assinanteId;
        this.assinante = model.assinante;
        this.sistemaId = model.sistemaId;
        this.sistema = model.sistema;
        this.nome = model.nome;
        this.ativo = model.ativo;

        this.itens = model.itens;
        this.modulos = model.modulos;
    }
}
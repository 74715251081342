import { http } from '@/ApiConfig';
import { Service } from '../shared/';

export class AssinanteService extends Service {

  constructor(){
    super('v1/assinante');
  }

  public ObterLogo(){
    return http.get(`${this.GetNomeControle()}/logo`);
  }
  
}